//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import moment from 'moment';
import { PatientService } from '@/services/patient.service';
import { DoctorService } from '@/services/doctor.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      doctorsInState: [],
      selectedDoctor: '',
      isProcessing: false
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        const patientData = await PatientService.getPatient(this.rowData.id);
        const {
          data
        } = await DoctorService.getDoctorsByState(patientData.data.state);
        this.doctorsInState = data;
      } else {
        this.doctorsInState = [];
        this.alert.show = false;
        this.selectedDoctor = '';
      }
    }

  },
  methods: {
    showAlert(msg) {
      this.alert.message = msg;
      this.alert.show = true;
    },

    getStates(doctor, abbrev = true) {
      let str = '';

      for (const state of doctor.coveredstate) {
        str += `${state.state}, `;
      }

      if (abbrev) {
        return str.substr(0, str.length - 2);
      } else {
        return `State(s): ${str.substr(0, str.length - 2)}`;
      }
    },

    async reassignPatient() {
      try {
        if (!this.selectedDoctor) {
          this.showAlert('Please select a doctor.');
          return;
        }

        const body = {
          doctorid: this.selectedDoctor
        };
        this.isProcessing = true;
        const {
          data
        } = await DoctorService.reassignPatient(this.rowData.id, body);

        if (data) {
          const selectedDoctor = data.doctorlistdetails.find(o => o.doctorid == this.selectedDoctor);
          this.$emit('set-doctor-name', `${selectedDoctor.firstname} ${selectedDoctor.lastname}`);
          this.isProcessing = false;
          this.showDialog = false;
        }
      } catch (err) {
        this.showAlert(err);
      }
    }

  }
};
/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "lg"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.alert.show } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4 pr-4" },
        [
          _c("b-col", { staticClass: "text-primary", attrs: { cols: "3" } }, [
            _vm._v(" New Reassignment ")
          ]),
          _c("b-col", [
            _c("span", { staticClass: "text-small text-muted" }, [
              _vm._v("Patient")
            ]),
            _c("br"),
            _c("span", [
              _vm._v(
                _vm._s(_vm.rowData.firstname) +
                  " " +
                  _vm._s(_vm.rowData.lastname)
              )
            ]),
            _c("br"),
            _c("span", { staticClass: "text-small text-muted mt-3" }, [
              _vm._v("State")
            ]),
            _c("br"),
            _c("span", [_vm._v(_vm._s(_vm.rowData.state))])
          ]),
          _c(
            "b-col",
            [
              _c("span", { staticClass: "text-primary text-small" }, [
                _vm._v("Reassign To")
              ]),
              _c(
                "b-row",
                { staticClass: "mb-3", attrs: { "h-align": "between" } },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-muted text-small" }, [
                      _vm._v("Doctor")
                    ])
                  ]),
                  _c("b-col", { attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "text-muted text-small" }, [
                      _vm._v("State")
                    ])
                  ])
                ],
                1
              ),
              _vm._l(_vm.doctorsInState, function(doctor, index) {
                return _c(
                  "b-row",
                  { key: index, attrs: { "h-align": "between" } },
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-radio",
                          {
                            attrs: {
                              value: doctor.id,
                              name: "selected-doctor"
                            },
                            model: {
                              value: _vm.selectedDoctor,
                              callback: function($$v) {
                                _vm.selectedDoctor = $$v
                              },
                              expression: "selectedDoctor"
                            }
                          },
                          [
                            _vm._v(
                              " Dr " +
                                _vm._s(doctor.firstname) +
                                " " +
                                _vm._s(doctor.lastname) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("b-col", { attrs: { cols: "3" } }, [
                      _vm._v(" " + _vm._s(_vm.getStates(doctor)) + " ")
                    ]),
                    _c("b-col", { attrs: { cols: "12" } }, [
                      _c("hr", {
                        staticStyle: {
                          "margin-top": "8px",
                          "margin-bottom": "8px"
                        }
                      })
                    ])
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "3" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-dark",
                            pill: "",
                            block: "",
                            disabled: _vm.isProcessing
                          },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(" Back ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "secondary",
                            pill: "",
                            block: "",
                            disabled: _vm.isProcessing
                          },
                          on: { click: _vm.reassignPatient }
                        },
                        [
                          _vm.isProcessing
                            ? _c("b-spinner", { attrs: { small: "" } })
                            : _c("span", [_vm._v(" Confirm ")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }